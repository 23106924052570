import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Button } from "../components/Button"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { CallToAction } from "../components/CTA"
import { Heading } from "../components/Hero"
import { Text, Image } from "../components/Core"
import { Columns, Section } from "../components/Containers"

class iCatPage extends React.Component {
  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    return (
      <SharedStateProvider>
        <Layout pageTitle="i-cat-page">
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            pathname={this.props.location.pathname}
            lang={this.props.pageContext.language}
          />
          {/* <Heading heading={post.heading} /> */}
          <div className="procedure-video-top-wrapper">
            <Image publicId={post.imageId} useAR />
            <div className="columns is-vcentered procedure-heading-container">
              <div className="column is-2" />

              <div className="column">
                {post.youtube && (
                  <div className="column procedures-theater-button is-hidden-tablet">
                    {post.youtube && (
                      <TheaterVideo
                        language="en"
                        videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                        controls
                        playing
                        overVideo
                        hasWatchVideo
                        buttonClass="contained mx-auto"
                      />
                    )}
                  </div>
                )}
                <div className="procedure-video-top-headings single-procedure-heading">
                  <Text as="h1" text={post.heading} />
                </div>
              </div>

              <div className="column is-4 procedures-theater-button is-hidden-mobile">
                {post.youtube && (
                  <TheaterVideo
                    language="en"
                    videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                    controls
                    playing
                    overVideo
                    hasWatchVideo
                    buttonClass="contained"
                  />
                )}
              </div>
              <div className="column is-2" />
            </div>
          </div>

          <Section>
            <Columns sideColumnsSize={5}>
              <div className="column">
                <Text text={post.body} />
              </div>
            </Columns>
          </Section>

          <CallToAction
            colorBack
            heading={post.getStarted.heading}
            blurb={post.getStarted.blurb}
            buttons={post.getStarted.buttons}
          />
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const pageQuery = graphql`
  query threeDImagingPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        blurb
        metaTitle
        metaDescription
        youtube
        imageId
        imageIdMobile
        body
        getStarted {
          blurb
          buttonText
          heading
          href
          buttons {
            button {
              appearance
              destination
              href
              buttonText
            }
          }
        }
      }
    }
  }
`

export default iCatPage
